import constate from "constate"
import { useState } from "react"

export const [ShopProvider, useShopContext] = constate(() => {
  const [shopId, setShopId] = useState()

  return {
    shopId,
  }
})
