import { Button, TextInput } from "@mantine/core"
import { SLACK_CHANNELS } from "const-values/slack"
import { useState } from "react"
import { Outlet } from "react-router-dom"

import { ShopProvider } from "contexts/ShopContext"

import usePostSlackMessage from "hooks/usePostSlackMsg"

import { inLocal } from "utils/environment"
import { t } from "utils/i18n"

import Notification from "components/molecules/notification"
import MainHeader from "components/organisms/main-header"

const MainLayout = () => {
  return (
    <div tw="w-screen h-full flex flex-col min-h-[586px]">
      {/* <IntercomSideEffects /> */}
      <MainHeader />
      {/* <MainSidebar />
        <LayoutErrorBoundary> */}
      <ShopProvider>{inLocal ? <Outlet /> : <ForSale />}</ShopProvider>
      {/* </LayoutErrorBoundary> */}
      {/* <GlobalModals /> */}
    </div>
  )
}

export default MainLayout

const ForSale = () => {
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")

  const postSlackMessage = usePostSlackMessage()
  const isValid = !!email.length && !!amount.length

  return (
    <div tw="flex-1 flex flex-col items-center gap-12 min-h-0 relative p-8">
      <div tw="text-[35px] text-green font-bold">Blockrite.com</div>
      <div tw="flex flex-row w-full gap-8 justify-between max-w-[1200px] items-start max-md:(flex-col items-center h-[80%])">
        <div tw="flex-1 flex justify-center max-md:flex-none">
          <div tw="flex flex-col w-fit justify-center">
            <div tw="text-20 font-bold">{t("main.forSale.title")}</div>
            <div tw="text-dark">{t("main.forSale.subtitle")}</div>
          </div>
        </div>
        <div tw="max-md:(flex-1 flex items-center)">
          <div tw="min-w-fit p-4 gap-2 flex flex-col bg-lightest shadow-sharp rounded-md">
            <div tw="text-20 font-bold">{t("main.forSale.offer")}</div>
            <TextInput
              placeholder={t("shared.email")}
              label={t("shared.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextInput
              placeholder="0"
              label={t("main.forSale.label.offerAmout")}
              value={amount}
              icon="﹩"
              onChange={(e) =>
                setAmount((Number(e.target.value.replace(/\D/g, "")) || "").toLocaleString())
              }
            />
            <Button
              tw="mt-2 disabled:bg-light"
              disabled={!isValid}
              onClick={() =>
                isValid &&
                postSlackMessage(
                  SLACK_CHANNELS.offersChannel.url,
                  SLACK_CHANNELS.offersChannel.payload({
                    text: `Email: ${email}\nAmount: ${amount}`,
                  })
                ).then(() => {
                  Notification.success({ message: t("notifications.offerSent", { email }) })
                  setEmail("")
                  setAmount("")
                })
              }
            >
              {t("shared.send")}
            </Button>
            <div tw="text-14">{t("contact")}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
