import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box } from "@chakra-ui/react"
import { BaseCategory } from "types/Category"

type Props = {
  category: BaseCategory
  clickHandler: () => void
}

const CategoryCard = ({ category, clickHandler }: Props) => {
  const { title, imageUrl } = category
  return (
    <Box
      as="a"
      onClick={clickHandler}
      className="group"
      tw="flex cursor-pointer overflow-hidden flex-col rounded-sm"
    >
      <div tw="w-60 h-60 overflow-hidden">
        <img
          tw="w-full h-full transition-transform duration-300 group-hover:[transform:scale(1.1)]"
          src={imageUrl}
        />
      </div>
      <div tw="p-2 flex items-center">
        <div tw="text-16 font-bold">{title}</div>
        <ChevronRightIcon boxSize={6} tw="duration-300 group-hover:[transform: translateX(8px)]" />
        {/* <ChevronRightIcon boxSize={6} /> */}
      </div>
    </Box>
  )
}

export default CategoryCard
