import ProductCard from "components/molecules/product-card"

const Products = () => {
  return (
    <div tw="mt-5 px-6">
      {/* <div tw="flex flex-wrap gap-3 justify-center">
        {new Array(50).fill(1).map(() => {
          return <ProductCard />
        })}
      </div> */}
    </div>
  )
}

export default Products
