import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

import axiosClient from "utils/axios"

import { BaseCategory } from "./../types/Category"

export const CATEGORIES_URL = "/categories/"

export const useCategories = (
  options?: Omit<UseQueryOptions<any, void>, "queryKey" | "queryFn">
) => {
  const { data, ...rest } = useQuery<AxiosResponse<BaseCategory[]>, void>(
    [CATEGORIES_URL],
    () => axiosClient.get("/categories"),
    options
  )

  return { data: data?.data, ...rest }
}
