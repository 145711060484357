export interface BaseCategory {
  title: string
  size: string
  imageUrl: string
  parentId: number
  id: number
  catalogPosition: number
}

// Gender
export enum UnisexCategory {
  UnisexShortSleeveTshirt = 24,
  UnisexRaglanSweatshirt = 7,
  UnisexHoodie = 28,
  UnisexCrewneckSweatshirt = 29,
  UnisexTank = 23,
  UnisexSweatpants = 98,
  UnisexLongSleeveTshirt = 26,
  UnisexBaseballTshirt = 25,
}

export enum MensCategory {
  MensShortSleeveTshirt = 6,
  MensTank = 23,
}

export enum LadiesCategory {
  LadiesShortSleeveCropTshirt = 31,
  LadiesShortSleeveTshirt = 32,
  LadiesHoodie = 36,
  LadiesCropSweatshirt = 9,
  LadiesTank = 30,
  LadiesDress = 11,
  LadiesRoundNeckShortSleeveTshirt = 8,
}

export enum KidsCategory {
  BabyShortSleeveTshirt = 12,
  BabyBodysuit = 14,
  KidsHoodie = 105,
  KidsBaseballTshirt = 145,
  KidsShortSleeveTshirt = 38,
}

export enum AccessoriesCategory {
  ToteBag = 48,
  DrawstringBag = 49,
}

export enum MainCategory {
  // Misc
  ToteBag = 48,
  DrawstringBag = 49,

  // Apparel
  BabyShortSleeveTshirt = 12,
  BabyBodysuit = 14,
  KidsHoodie = 105,
  KidsBaseballTshirt = 145,
  KidsShortSleeveTshirt = 38,
  LadiesShortSleeveCropTshirt = 31,
  LadiesShortSleeveTshirt = 32,
  LadiesHoodie = 36,
  LadiesCropSweatshirt = 9,
  LadiesTank = 30,
  LadiesDress = 11,
  LadiesRoundNeckShortSleeveTshirt = 8,
  MensShortSleeveTshirt = 6,
  MensTank = 23,
  UnisexShortSleeveTshirt = 24,
  UnisexRaglanSweatshirt = 7,
  UnisexHoodie = 28,
  UnisexCrewneckSweatshirt = 29,
  UnisexTank = 23,
  UnisexSweatpants = 98,
  UnisexLongSleeveTshirt = 26,
  UnisexBaseballTshirt = 25,
}
