import { ChevronRightIcon } from "@chakra-ui/icons"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button } from "@chakra-ui/react"
import { isNil, omitBy } from "lodash"
import { useMemo, useState } from "react"
import {
  AccessoriesCategory,
  BaseCategory,
  KidsCategory,
  LadiesCategory,
  MainCategory,
  MensCategory,
  UnisexCategory,
} from "types/Category"
import { Product } from "types/Product"

import { useCategories } from "hooks/useCategories"

import CategoryCard from "components/molecules/category-card"
import ProductCard from "components/molecules/product-card"

import { useProducts } from "./hooks"

enum Gender {
  Mens,
  Ladies,
  Unisex,
  Kids,
  Accessories,
}

const genderMap = {
  [Gender.Mens]: Object.values(MensCategory).filter(Number),
  [Gender.Ladies]: Object.values(LadiesCategory).filter(Number),
  [Gender.Kids]: Object.values(KidsCategory).filter(Number),
  [Gender.Unisex]: Object.values(UnisexCategory).filter(Number),
  [Gender.Accessories]: Object.values(AccessoriesCategory).filter(Number),
}

enum Page {
  Home,
  Products,
}

type ActiveFilters = {
  mainCategoryId?: BaseCategory["id"]
}

const Design = () => {
  const [page, setPage] = useState<Page>(Page.Home)
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({})
  const [activeCategory, setActiveCategory] = useState<BaseCategory | null>(null)
  const { data: products, isInitialLoading: isProductsLoading } = useProducts(activeCategory?.id, {
    enabled: !!activeCategory?.id,
  })
  const { data: categories, isLoading: isCategoriesLoading } = useCategories()

  const mainCategories = useMemo(
    () => categories?.filter(({ parentId }) => !parentId),
    [categories]
  )
  const childCategories = useMemo(
    () =>
      categories?.filter(
        ({ parentId, catalogPosition }) => !!catalogPosition && activeCategory?.id === parentId
      ),
    [activeCategory]
  )
  const filteredProducts = useMemo(
    () =>
      Object.keys(omitBy(activeFilters, isNil)).length
        ? products?.filter(({ mainCategoryId }) => mainCategoryId === activeFilters?.mainCategoryId)
        : products,
    [activeFilters?.mainCategoryId, products]
  )

  const isLoading = isProductsLoading || isCategoriesLoading

  if (isCategoriesLoading || !categories) return <div>Loading...</div>

  return (
    <div tw="mt-5 px-6">
      <div>
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink
              onClick={() => {
                setPage(Page.Home)
                setActiveCategory(null)
              }}
            >
              Products
            </BreadcrumbLink>
          </BreadcrumbItem>

          {activeCategory && (
            <BreadcrumbItem>
              <BreadcrumbLink>{activeCategory.title}</BreadcrumbLink>
            </BreadcrumbItem>
          )}
        </Breadcrumb>
      </div>
      <div tw="flex flex-row gap-4">
        <div tw="flex flex-wrap gap-2 h-fit min-w-[250px] w-[250px]">
          {!isLoading &&
            page === Page.Products &&
            childCategories?.map((cat) => (
              <Button
                key={cat.id}
                variant={cat.id === activeFilters.mainCategoryId ? "outline" : undefined}
                size="sm"
                onClick={() =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    mainCategoryId: activeFilters.mainCategoryId === cat.id ? undefined : cat.id,
                  }))
                }
              >
                {cat.title}
              </Button>
            ))}
        </div>
        <div tw="flex flex-wrap gap-3 justify-center">
          {isLoading && <div>Loading...</div>}
          {!isLoading &&
            page === Page.Home &&
            mainCategories?.map((category) => (
              <CategoryCard
                key={category.id}
                category={category}
                clickHandler={() => {
                  setActiveCategory(category)
                  setPage(Page.Products)
                  setActiveFilters({})
                }}
              />
            ))}

          {!isLoading &&
            page === Page.Products &&
            !!filteredProducts?.length &&
            filteredProducts.map((product) => <ProductCard key={product.id} product={product} />)}
        </div>
      </div>
    </div>
  )
}

export default Design
