import { Box } from "@chakra-ui/react"
import { Product } from "types/Product"

type Props = {
  product: Product
}

const ProductCard = ({ product }: Props) => {
  const { brand, image, model } = product
  return (
    <Box
      as="a"
      tw="flex overflow-hidden flex-col shadow-card w-48 rounded-sm transition-shadow hover:shadow-card-hover"
    >
      <div tw="w-48 h-48">
        <img tw="w-full h-full" src={image} />
      </div>
      <div tw="p-2">
        <div>brand: {brand}</div>
        <div>name: {model}</div>
        <div>$35.56</div>
      </div>
    </Box>
  )
}

export default ProductCard
