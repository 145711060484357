export const SLACK_CHANNELS = {
  offersChannel: {
    url: "https://hooks.slack.com/services/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",
    payload: (properties: object) => ({
      username: "NEW OFFER",
      attachments: [
        {
          fallback: "This attachement isn't supported",
          title: "NEW OFFER -- Blockrite.com",
          color: "#85da3a",
          pretext: "Someone has sent you an offer 🥳🥳🥳",
          mrkdwn_in: ["text", "fields"],
          ...properties,
        },
      ],
    }),
  },
  // salesChannel: {
  //   url: "https://hooks.slack.com/services/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",
  //   payload: {
  //     username: "SALES BOT",
  //     attachments: [
  //       {
  //         fallback: "This attachement isn't supported",
  //         title: "SALES INQUIRY",
  //         color: "#85da3a",
  //         pretext: "Someone has attempted to contact Chameleon",
  //         fields: [
  //           {
  //             title: "Tracking",
  //             value:
  //               "_<https://analytics.google.com/analytics/web/#/p312557714/reports/intelligenthome|Google Analytics>_",
  //             short: true,
  //           },
  //           {
  //             title: "Location",
  //             value: "",
  //             short: true,
  //           },
  //         ],
  //         mrkdwn_in: ["text", "fields"],
  //       },
  //     ],
  //   },
  // },
}
