import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { Product } from "types/Product"

import axiosClient from "utils/axios"

export const PRODUCTS_URL = "/products/"

export const useProducts = (
  categoryId?: number,
  options?: Omit<UseQueryOptions<any, void>, "queryKey" | "queryFn">
) => {
  const { data, ...rest } = useQuery<AxiosResponse<Product[]>, void>(
    [PRODUCTS_URL, { categoryId }],
    () => axiosClient.get("/products", { params: { category_id: categoryId } }),
    options
  )

  return { data: data?.data, ...rest }
}
