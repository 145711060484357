// import Button from "components/atoms/button"
import { Button } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

import { ReactComponent as BlockRiteLockup } from "../../../assets/svgs/blockrite-lock.svg"

const MainHeader = () => {
  return (
    <div tw="sticky top-0 bg-white w-full shadow-sharp">
      <div tw="flex items-center justify-between py-2 px-4">
        <NavLink to="/">
          <BlockRiteLockup tw="w-[120px]" />
        </NavLink>
        <Button as="a" href="/design">
          Design
        </Button>
      </div>
    </div>
  )
}

export default MainHeader
