import axios, { AxiosResponse } from "axios"
import camelcaseKeys from "camelcase-keys"

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
})

axiosClient.interceptors.response.use((response: AxiosResponse) => {
  if (response.data && response.headers["content-type"].includes("application/json")) {
    response.data = camelcaseKeys(response.data, { deep: true })
  }

  return response
})

export default axiosClient
