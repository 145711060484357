import { ChakraProvider } from "@chakra-ui/react"
// import { MantineProvider } from "@mantine/core";
// import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import GlobalStyles from "GlobalStyles"
import React from "react"
import ReactDOM from "react-dom/client"
import ReactGA from "react-ga4"
import { BrowserRouter, Route, Routes } from "react-router-dom"

// import mantineProvider from "contexts/mantine-provider";
import { inLocal } from "utils/environment"
import "utils/i18n"

import AppContainer from "containers/app-container"
import MainLayout from "containers/main-layout/MainLayout"

import Design from "pages/design"

import Products from "components/organisms/products"

import "./index.css"

if (!inLocal) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!)

  Sentry.init({
    // This url is safe to be public and checked into Git. https://forum.sentry.io/t/how-does-sentry-prevent-spammers/8188/2
    dsn: "https://134ca8dc554c4017aeab35a77292e6ea@o4505472259719168.ingest.sentry.io/4505472294584320",
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [/^https:\/\/.*\.blockrite\.com\/.*/],
        // While in beta, we don't mask or block user context in these error reports so we can get as much
        // context as possible. We should revisit when we have paying customers
        maskAllText: false,
        blockAllMedia: false,
      }),
      new Sentry.BrowserTracing({
        // Do not trace from localhost. This breaks our internal testing of Google Analytics for dumb reasons
        // See https://github.com/getsentry/sentry-javascript/issues/5868.
        tracingOrigins: [/^\//],
      }),
    ],
    // Allows us to differentiate between testing and prod errors
    environment: process.env.REACT_APP_UI_ENVIRONMENT!,
    // Allows us to differentiate between different versions of the UI
    // release: process.env.REACT_APP_RELEASE_HASH!,
    // The rate at which we do performance traces
    tracesSampleRate: 0.1,
    // The rate at which we get Sentry replays
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
}

// TimeAgo.addDefaultLocale(en)

window.React = React

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 2,
      staleTime: 5 * 1000,
    },
  },
})
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <GlobalStyles />
      {/* <Notifications /> */}
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen />
        {/* <MantineProvider withGlobalStyles withNormalizeCSS theme={mantineProvider}> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AppContainer />}>
              <Route path="" element={<MainLayout />}>
                <Route path="" index element={<Products />} />
                <Route path="design" element={<Design />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        {/* </MantineProvider> */}
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
)
